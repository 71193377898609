import React from "react";
import { ToastContainer } from "react-toastify";

import Action from "./Action";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import Introduction from "./Introduction";
import Team from "./Team";
import Transition from "./Transition";
import What from "./What";
import "./Global.scss";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <Introduction />
      <Action />
      <Transition />
      <What />
      <Team />
      <Contact />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
