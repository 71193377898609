import "./Global.scss";
import "./Action.scss";
import ccbtc from "./assets/icon/ccbtc.svg";
import wind from "./assets/icon/wind.svg";
import btc from "./assets/icon/btc-icon-full.svg";
import realt from "./assets/icon/realt.svg";
import Newsletter from "./Newsletter";
import { useTranslation } from "react-i18next";

function Action() {
  const { t } = useTranslation();
  return (
    <div className="Action" id="Action">
      <div className="content">
        <div className="Inner">
          <p>
            {t('action.firstTime')}
            <strong className="Green"> {t('action.industry')} </strong>{t('action.quality')}
          </p>
          <h2 className="Subtitle text-center">{t('action.poweredBy')}</h2>
          <div className="Row Evenly Black Small CenterX" id="action-icons">
            <div className="Column CenterAll">
              <img src={ccbtc} alt={t('alt.ccbtc')} className="Logo" />
              <h4>{t('action.mining')}</h4>
            </div>
            <div className="Column CenterAll">
              <img src={wind} alt={t('alt.wind')} className="Logo" />
              <h4>{t('action.ecology')}</h4>
            </div>
            <div className="Column CenterAll">
              <img src={realt} alt={t('alt.greenrealtlogo')} className="Logo" />
              <h4>{t('action.tokenized')}</h4>
            </div>
            <div className="Column CenterAll">
              <img src={btc} alt={t('alt.btclogo')} className="Logo" />
              <h4>{t('action.bitcoin')}</h4>
            </div>
          </div>
          <Newsletter />
        </div>
      </div>
    </div>
  );
}

export default Action;
