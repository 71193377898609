import "./Global.scss";
import "./Contact.scss";
import Newsletter from "./Newsletter";
import btc from "./assets/icon/btc-icon-full.svg";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  return (
    <div className="Contact">
      <Newsletter isBlack={true} />
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-7" />
    </div>
  );
}

export default Contact;
