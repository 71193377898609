import { useTranslation } from "react-i18next";
import btc from "./assets/icon/btc.svg";
import fleches from "./assets/icon/fleches.svg"
import realt from "./assets/icon/CleanSatMining-txt-Black.svg"
import "./Global.scss";
import "./Introduction.scss";

function Introduction() {
  const { t } = useTranslation();

  return (
    <div className="Introduction">
      <div className="ImgBackground"><div className="BackgroundVoile"></div></div>
      <div className="intro-content content">
        <div className="title_welcome"><p>{t("introduction.welcome")}</p></div>
          <div className="logo-full">
            <div className="logo-txt">
              <img src={realt} className="App-logo logo-realt" alt={t('alt.spinningbtclogo')} />
            </div>
            <div className="logo-B">
              <img src={fleches} className="App-logo logo-fleches animate-logo" alt={t('alt.spinningbtclogo')}/>
              <img src={btc} className="App-logo logo-btc" alt={t('alt.spinningbtclogo')}/>
            </div>
          </div>
          <div className="slogan">{t('introduction.different')}</div>
        </div>
        {/*
        <div className="Row">
          <div className="Catch">
            <div className="Row">
              <h1>Real</h1>
              <h1 className="Green">T</h1>
            </div>
          </div>
            <h1 className="Clean">{t('introduction.clean')}</h1>
          <img src={fleches} className="App-logo logo-fleches animate-logo big" alt={t('alt.spinningbtclogo')}/>
          <img src={btc} className="App-logo big" alt={t('alt.gpubtc')}></img>
        </div>
        */}
    </div>
  );
}

export default Introduction;
