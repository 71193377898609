import i18next from "i18next";
import { useTranslation } from "react-i18next";
import btc from "./assets/icon/btc.svg";
import fleches from "./assets/icon/fleches.svg"
import "./Header.scss";
import "./Global.scss";

function Header() {
  const { t } = useTranslation();
  const isFR = i18next.languages[0] === "fr";

  return (
    <header className="App-header">
      <div className="content">
        <div className="flex-wrap space-between">
          <div className="Header-logo">
              <img src={fleches} className="App-logo logo-fleches animate-logo" alt={t('alt.spinningbtclogo')}/>
              <img src={btc} className="App-logo logo-btc" alt={t('alt.spinningbtclogo')}/>
          </div>
          <div className="Header-left">
            <a href="#Action">{t("header.cm")}</a>|
            <a href="#What">{t("header.what")}</a>|
            <a href="#Team">{t("header.team")}</a>
          </div>
          <div className="Header-right">
            <h6
              className="Lang"
              onClick={() => i18next.changeLanguage("fr")}
              style={{ 
                fontWeight: isFR ? "bold" : "normal",
                color: isFR ? "#B5CD30" : "#fff"
              }}
            >
              FR
            </h6>
            |
            <h6
              className="Lang"
              onClick={() => i18next.changeLanguage("en")}
              style={{ 
                fontWeight: isFR ? "normal" : "bold", 
                color: isFR ? "#fff" : "#B5CD30"
              }}
            >
              EN
            </h6>
            <a className="ContactBtn" href="mailto:contact@cleansatmining.com">{t('header.contact')}</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
