import "./Global.scss";
import "./Team.scss";
import guillaume from "./assets/team/Guillaume-Goualard-bw.png";
import jeanmarc from "./assets/team/Jean-Marc-Jacobson-bw.png";
import michael from "./assets/team/Michael-Courvoisier-bw.png";
import remy from "./assets/team/Remy-Jacobson-bw.png";
import sebastien from "./assets/team/Sebastion-Gouspillou-bw.png";
import richard from "./assets/team/Richard-Detente-bw.png";
import btc from "./assets/icon/btc-icon-full.svg";
import { useTranslation } from "react-i18next";

interface MemberProps {
  firstname: string;
  lastname: string;
  job: string;
  picture: string;
}

function Member({ firstname, lastname, job, picture }: MemberProps) {
  return (
    <div className="Member">
      <img src={picture} alt={`${firstname} ${lastname}`} className="Picture" />
      <p className="Name"><span className="Black">{firstname}</span><br />
       <span className="Green">{lastname}</span></p>
      <h6 className="Job Black">{job}</h6>
    </div>
  );
}
function Team() {
  const { t } = useTranslation();
  return (
    <div className="Team" id="Team">
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-8" />
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-9" />
      <div className="content">
      <h2 className="TeamTitle Black">{t('team.title')}</h2>
        <div className="team-layout">
          <div className="flex-wrap team-top">
            <Member
              firstname="Jean Marc"
              lastname="Jacobson"
              job="Cofounder RealT"
              picture={jeanmarc}
            />
            <Member
              firstname="Rémy"
              lastname="Jacobson"
              job="Cofounder RealT"
              picture={remy}
            />
            <Member
              firstname="Michael"
              lastname="Courvoisier"
              job="COO RealT"
              picture={michael}
            />
          </div>
          <div className="flex-wrap team-bottom">
            <Member
              firstname="Sébastien"
              lastname="Gouspillou"
              job="Founder & CEO BBGS"
              picture={sebastien}
            />
            <Member
              firstname="Richard"
              lastname="Détente"
              job="Cofounder Grand Angle Crypto"
              picture={richard}
            />
            <Member
              firstname="Guillaume"
              lastname="Goualard"
              job="Cofounder Grand Angle Crypto"
              picture={guillaume}
            />
          </div>
        </div>
      </div>
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-6" />
    </div>
  );
}

export default Team;
