import { useEffect, useState } from "react";
import "./Global.scss";
import "./Newsletter.scss";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface NewsletterProps {
  isBlack?: boolean;
}

const url =
  "https://us-central1-corsanywhere-4e586.cloudfunctions.net/proxyWithCorsAnywhere/https://richarddetente.activehosted.com/proc.php?f=19&m=0&act=sub&v=2&jsonp=true&email=";
function Newsletter({isBlack}: NewsletterProps) {
  const [mail, setMail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { t } = useTranslation()

  const subscribe = () => {
    fetch(url + mail)
      .then(() => {
        toast.success("🦄 " + t('newsletter.success'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("🦄 " + t('newsletter.error'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    if (
      mail.match(new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"))
    )
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [mail]);

  return (
    <div className="CenterAll">
      <h2 className="Newstitle" style={{color: isBlack ? "white": "rgba(25, 31, 41, 1)"}}>{t('newsletter.title')}</h2>
      <div className="Row form-wrapper">
        <input
          type="mail"
          placeholder={t('newsletter.placeholder')}
          onChange={(e) => setMail(e.target.value)}
        />
        <button
          onClick={subscribe}
          className="Go"
          style={{ opacity: isDisabled ? 0.3 : 1, cursor: isDisabled ? 'initial' : 'pointer' }}
        >
          {t('newsletter.go')}
        </button>
      </div>
    </div>
  );
}

export default Newsletter;
