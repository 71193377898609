import { useTranslation } from "react-i18next";
import "./Global.scss";
import Newsletter from "./Newsletter";
import "./What.scss";
import btc from "./assets/icon/btc-icon-full.svg";

function What() {
  const { t } = useTranslation();
  return (
    <div className="What" id="What">
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-4" />
      <div className="content">
        <div className="Inner">
        <h2 className="Title Black text-center">{t('what.title')}</h2>
        <p className="Sub Grey">
          {t('what.first')}
          <br />
          {t('what.tokenized')}
          <br />
          {t('what.powered')}
          <br />
          {t('what.renewable')}
          <br />
        </p>
        <Newsletter />
        </div>
      </div>
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-5" />
    </div>
  );
}

export default What;
