import { useTranslation } from "react-i18next";
import "./Global.scss";
import "./Transition.scss";
import btc from "./assets/icon/btc-icon-full.svg";

function Transition() {
  const { t } = useTranslation();
  return (
    <div className="Transition">
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-1" />
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-2" />
      <div className="content">
        <div className="flex-wrap">
          <p><span className="Grey">{t('transition.part')}</span><br/>
          <span className="LightGrey">{t('transition.techno')}</span><span className="Grey">,</span><br/>
          <span className="Green">{t('transition.eco')}</span><br/>
          <span className="Grey">{t('transition.and')}</span> <span className="Black">{t('transition.monetary')}</span> <span className="Grey">{t('transition.et')}</span><br/>
          <span className="Grey">{t('transition.revolution')}</span></p>
        </div>
      </div>
      <div className="windmill-bg-wrapper"></div>
      <img src={btc} alt={t('alt.btclogo')} className="bg-icons" id="bg-icon-3" />
    </div>
  );
}

export default Transition;
